import type { WeekdayNumbers } from "luxon";
import { DateTime } from "luxon";

import { Weekday } from "../generated/gateway-client";

const weekdayMap: Record<Weekday, WeekdayNumbers> = {
  [Weekday.Mon]: 1,
  [Weekday.Tue]: 2,
  [Weekday.Wed]: 3,
  [Weekday.Thu]: 4,
  [Weekday.Fri]: 5,
  [Weekday.Sat]: 6,
  [Weekday.Sun]: 7,
};

export const formatWeekday = (weekday: Weekday, language: string, format: "short" | "narrow" = "short"): string => {
  // As a general rule, rolling your own custom localised formatters like this
  // is a bad idea and should be avoided.
  // However, in this case, this format is only relevant here and and we
  // explicitly * don't* want it reused elsewhere.
  const dayOfWeekFormatter = new Intl.DateTimeFormat(language, { weekday: format });

  const weekdayNum = weekdayMap[weekday];

  const d = DateTime.local().set({ weekday: weekdayNum }).toJSDate();

  return dayOfWeekFormatter.format(d);
};

export const groupWeekday = (raw: Weekday[], language: string) => {
  if (raw.length === 0) {
    return raw;
  }

  if (raw.length === 1) {
    return formatWeekday(raw[0], language);
  }

  const days = [...raw].sort((a: Weekday, b: Weekday) => weekdayMap[a] - weekdayMap[b]);
  const daySequence = days.map((d) => weekdayMap[d]);
  const firstNumber = daySequence[0];
  const daysNotSequence = daySequence.some((dayIndex, index) => dayIndex !== firstNumber + index);

  return daysNotSequence
    ? days.map((day) => formatWeekday(day, language)).join(", ")
    : `${formatWeekday(days[0], language)} - ${formatWeekday(days[days.length - 1], language)}`;
};
