import type { TFunction } from "@equiem/localisation-eq1";
import type { FormValues } from "./BookingDiscountFormValues";
import type { FormikErrors } from "formik";
import { stringNotEmpty } from "@equiem/lib";
import { getEndTime, getStartTime } from "../../settings/components/blockout/helpers/validateAddForm";
import { BookingDiscountType } from "../../../generated/gateway-client";
import { toSeconds } from "../../../lib/dateTimeHelpers";

export const validateForm =
  (t: TFunction, timezone: string) =>
  // eslint-disable-next-line complexity
  (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};
    if (!stringNotEmpty(values.title)) {
      errors.title = t("bookings.settings.pleaseFillTheTitle");
    }
    if (!stringNotEmpty(values.startDate)) {
      errors.startDate = t("bookings.settings.pleaseSelectADate");
    }
    if (!stringNotEmpty(values.endDate)) {
      errors.endDate = t("bookings.settings.pleaseSelectADate");
    }
    if (stringNotEmpty(values.startDate) && stringNotEmpty(values.endDate)) {
      const start = getStartTime(timezone, values.startDate, "");
      const end = getEndTime(timezone, values.endDate, "");

      if (!start.isValid) {
        errors.startDate = t("bookings.settings.pleaseEnterAValidDate");
      }
      if (!end.isValid) {
        errors.endDate = t("bookings.settings.pleaseEnterAValidDate");
      }
      if (start.isValid && end.isValid && start >= end) {
        errors.startDate = t("bookings.settings.startShouldBeBeforeEndDateAndTime");
      }
    }
    if (values.type === BookingDiscountType.EarlyBooking && values.availability.daysBeforeBookingToApply == null) {
      errors.availability = {
        daysBeforeBookingToApply: t("bookings.discounts.numberOfDaysFieldError"),
      };
    }

    if (values.type === BookingDiscountType.Periodic) {
      if ((values.availability.days?.length ?? 0) === 0) {
        errors.availability = {
          ...errors.availability,
          days: t("bookings.discounts.availabilitiesDaysFieldError"),
        };
      }

      if (!stringNotEmpty(values.availability.startTime) && stringNotEmpty(values.availability.endTime)) {
        errors.availability = {
          ...errors.availability,
          startTime: t("bookings.lib.selectStartTime"),
        };
      }

      if (stringNotEmpty(values.availability.startTime) && !stringNotEmpty(values.availability.endTime)) {
        errors.availability = {
          ...errors.availability,
          endTime: t("bookings.lib.selectEndTime"),
        };
      }

      if (stringNotEmpty(values.availability.startTime) && stringNotEmpty(values.availability.endTime)) {
        const start = toSeconds(values.availability.startTime);
        const end = toSeconds(values.availability.endTime);
        if (start != null && end != null && start >= end) {
          errors.availability = {
            ...errors.availability,
            startTime: t("bookings.lib.startBeforeEndTime"),
          };
        }
      }
    }

    if (values.percentage == null || !stringNotEmpty(values.percentage.toString())) {
      errors.percentage = t("bookings.discounts.percentageFieldError");
    }
    if (values.resources.length === 0) {
      errors.resources = t("bookings.settings.pleaseSelectAResource");
    }

    return errors;
  };
